import React, {useState} from 'react';
import styles from './Schedule.module.scss';
import Modal from "../modal/Modal";

const data = [
    {
        id: 0,
        name: "Нафиг универ",
        description: 'Мы предлагаем мастер-классы, практические занятия и интересные встречи с представителями профессий.',
        date: '13.07.2024'
    },
    {
        id: 1,
        name: "Нафиг универ",
        description: 'Мы предлагаем мастер-классы, практические занятия и интересные встречи с представителями профессий.',
        date: '13.07.2024'
    },
    {
        id: 2,
        name: "Нафиг универ",
        description: 'Мы предлагаем мастер-классы, практические занятия и интересные встречи с представителями профессий.',
        date: '13.07.2024'
    },
]

const Schedule = ({dimensions}) => {
    const [isOpen, setIsOpen] = useState(false);
    const closeModal = () => {
        setIsOpen(false);
    };
    const openModal = () => {
        setIsOpen(true);
    };
    return (
        <div className={styles.container}>
            {isOpen && <Modal isOpen={isOpen} onClose={closeModal} />}
            <span className={styles.header}>Ближайшие встречи</span>
            {dimensions.width > 768
                ? <>
                    <div className={styles.rowHeader}>
                        <span className={styles.firstCol}>Название встречи</span>
                        <span className={styles.secondCol}>Описание встречи</span>
                        <span className={styles.thirdCol}>Дата</span>
                        <div className={styles.fourthCol} />
                    </div>
                    {data.map(item => <div key={item.id} className={styles.row}>
                        <span className={styles.firstCol}>{item.name}</span>
                        <span className={styles.secondCol}>{item.description}</span>
                        <span className={styles.thirdCol}>{item.date}</span>
                        <div className={styles.fourthCol}>
                            <button
                                onClick={openModal}
                                className={styles.enrolBtn}
                            >
                                Записаться
                            </button>
                        </div>
                    </div>)}
                </>
                : data.map(item => <div key={item.id} className={styles.mobileItem}>
                    <div className={styles.mobileFirstLine}>
                        <div className={styles.mobileLine}>
                            <span className={styles.mobileHeader}>Название встречи</span>
                            <span>{item.name}</span>
                        </div>
                        <div className={styles.mobileLine}>
                            <span className={styles.mobileHeader}>Дата</span>
                            <span>{item.date}</span>
                        </div>
                    </div>
                    <div className={styles.mobileLine}>
                        <span className={styles.mobileHeader}>Описание встречи</span>
                        <span>{item.description}</span>
                    </div>
                    <div>
                        <button
                            onClick={openModal}
                            className={styles.enrolBtn}
                        >
                            Записаться
                        </button>
                    </div>
                </div>)}
        </div>
    )
}

export default Schedule;