// Modal.js
import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import styles from './Modal.module.scss';

const Modal = ({ isOpen, onClose }) => {
    const [user, setUser] = React.useState({
        name: '',
        phone: '',
        nick: ''
    });
    const [registered, setRegistered] = useState(localStorage.getItem('registration'));
    console.log(isOpen, '----------')
    const handleChange = (key, val) => {
      setUser({...user, [key]: val});
    }
    if (!isOpen) return null;
    // useEffect(() => {
    const handleRegistration = (data) => {
        fetch('https://bug-api.ebat.me/registration', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(r => r.json())
            .then(data => {
                localStorage.setItem('registration', JSON.stringify(data));
                window.location.href = 'https://t.me/+jVSl-g6HVRRjODM6';
            })
            .catch(error => console.error('Error creating user:', error));
    }
    // }, [user])
    return ReactDOM.createPortal(
        <div className={styles.modalOverlay}>
            <div className={styles.modal}>
                <div className={styles.headerContainer}>
                    <span className={styles.title}>Регистрация</span>
                    <button className={styles.modalClose} onClick={onClose}>
                        &times;
                        </button>
                    </div>
                <div className={styles.modalContent}>
                    {registered && <span className={styles.confirmation}>Спасибо за регистрацию! Мы получили твои данные и скоро с тобой свяжемся</span>}
                    {!registered && <>
                    <div className={styles.row}>
                        <span className={styles.header}>Имя</span>
                        <input
                            className={styles.input}
                            placeholder="Введите имя"
                            onChange={(e) => handleChange('name', e?.target?.value)}
                        />
                    </div>
                    <div className={styles.row}>
                        <span className={styles.header}>Телефон</span>
                        <input
                            className={styles.input}
                            placeholder="Введите номер телефона"
                            onChange={(e) => handleChange('phone', e?.target?.value)}
                        />
                    </div>
                    <div className={styles.row}>
                        <span className={styles.header}>Ник telegram</span>
                        <input
                            className={styles.input}
                            placeholder="Введите ник в telegram"
                            onChange={(e) => handleChange('nick', e?.target?.value)}
                        />
                    </div>
                    <div className={styles.btn}>
                        <button
                            onClick={() => handleRegistration(user)}
                            className={styles.enrolBtn}
                            disabled={user.name === '' || user.phone === '' || user.nick === ''}
                        >
                            Отправить
                        </button>
                    </div>
                </>}
                </div>
            </div>
        </div>,
        document.getElementById('portal-root')
    );
};

export default Modal;
