import React from 'react';
import styles from './Main.module.scss';
import loaf from '../../assets/loaf.svg';
import whiteLoaf from '../../assets/white loaf.svg';
import bottomCloud from '../../assets/bottom_cloud.svg';
import bush from '../../assets/bush.svg';
import basicTree from '../../assets/basicTree.svg';
import rightTree from '../../assets/bunTree.svg';
import cloud from '../../assets/invite_cloud.svg';

const Main = ({dimensions}) => (
    <div className={styles.container}>
        <div className={styles.headerContainer}>
            <div className={`${styles.leftBun} ${styles.headerBun}`}>
                <img src={whiteLoaf} alt='loaf' className={styles.bunIc}/>
            </div>
            <h1 className={styles.header}>Булки на деревьях</h1>
            <div className={`${styles.rightBun} ${styles.headerBun}`}>
                <img src={loaf} alt='loaf' className={styles.bunIc}/>
            </div>
        </div>
        <div className={styles.inviteContainer}>
            <div className={`${styles.leftBunInvite} ${styles.inviteBun}`}>
                <img src={loaf} alt='loaf' className={styles.bunIc}/>
            </div>
            <img src={cloud} alt='cloud' className={styles.inviteCloud}/>
            <span className={styles.invite}>{
                dimensions.width > 768
                    ? <>Присоединяйтесь к нам и откройте<br/> для себя мир профессий!</>
                    : <>Присоединяйтесь к нам<br/> и откройте для себя<br/> мир профессий!</>}
            </span>
            <div className={`${styles.rightBunInvite} ${styles.inviteBun}`}>
                <img src={whiteLoaf} alt='loaf' className={styles.bunIc}/>
            </div>
        </div>
        <div className={styles.bottomCloudContainer}>
            <img src={bottomCloud} alt='cloud' className={styles.cloudIc}/>
            {/*<img src={leftTree} alt='tree' className={styles.leftTree} />*/}
            <img src={bush} alt='bush' className={styles.bushIc} />
            <img src={basicTree} alt='tree' className={styles.basicTree} />
            <img src={rightTree} alt='tree' className={styles.rightTree} />
            <span className={styles.bottomText}>Профориентационный проект, направленный<br/>
                на помощь подросткам в выборе профессии,<br/>
                чтобы каждый смог найти свое призвание. </span>
            <div className={styles.cloudTagContainer}>
                <span className={styles.tag}>Практические задания</span>
                <span className={styles.tag}>Мастер-классы</span>
                <span className={styles.tag}>Встречи</span>
            </div>
        </div>
    </div>
)

export default Main;