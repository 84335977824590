import React, {useState, useEffect} from 'react';
import './App.css';
import Main from "./pages/main/Main";
import Schedule from "./pages/schedule/Schedule";

function App() {
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }
    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
    }, []);

    return (
        <>
            <Main dimensions={dimensions} />
            <Schedule dimensions={dimensions} />
        </>
    );
}

export default App;
